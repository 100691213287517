import { DbId } from './types';

export default class Endpoints {
    static login = '/v1/token/';

    static logout = '/v1/logout/';

    static forgotPassword = '/v1/email-reset-password/';

    static recoverPassword = '/v1/reset-password/'

    static resetPassword = '/v1/password/reset/confirm/';
    
    static changePassword = '/v1/change-password/';

    static register = '/v1/users/create/';

    static useTerms = '/v1/terms_of_use/';
    
    static dashboard = '/v1/dashboard/';

    static product = '/v1/product/';

    static productPhotos = '/v1/produt_photos/';

    static productCategory = '/v1/produt_category/';

    static productSection = '/v1/sections/';

    static address = '/v1/address/';

    static order = '/v1/order/';

    static shop = '/v1/product/';
    
    static cart = '/v1/shopping_cart/';
    static cartClean = '/v1/clean-shopping-cart/';
    static cartShipCost = '/v1/cart-shipping-cost/';
    static cartInstallments = '/v1/cart-installments/';
    
    static creditCard = '/v1/credit_card';

    static user = '/v1/users/';

    static store = '/v1/store/';

    static bank = '/v1/banks/';

    static bankAccounts = '/v1/bankaccounts/';

    static plan = '/v1/plans';
    static subscription = '/v1/subscription';
    static signatureFree = '/v1/signature_free';

    static productShareStock = (id: DbId) => `/v1/product/${id}/share/`;

    static store_sales = '/v1/store_sales';

    static paymentProof = '/v1/store_payment_proof';
}
